const Project = () => {
    return (
        <>
            <h1>Project</h1>
            <p>
                <a href="https://cssgrid-generator.netlify.app/" rel="noreferrer" target="_blank">CSS grid</a>
            </p>
            <p >
                <a href="https://hamburger-react.netlify.app/" rel="noreferrer" target="_blank">Hamburger menu</a>
            </p >
            <p >
                <a href="https://aforamitrai.medium.com/how-to-deploy-react-app-on-shared-hosting-cpanel-d682b0342424" rel="noreferrer" target="_blank">.htaccess file</a>
            </p >
            <p >
                <a href="https://reactrouter.com/en/main/routers/picking-a-router" rel="noreferrer" target="_blank">React router 6.4</a>
            </p >
            <p>
                <a href="https://Strato.com" rel="noreferrer" target="_blank">Strato.com</a>
            </p>
        </>
    )
};

export default Project;