import { Link } from 'react-router-dom';
import ProgrammingIMG from '../img/programming.png';
import ProjectCard from '../components/projectCards';
import { programTitle, programText, loremIpsumTitle, loremIpsumText } from '../texts/texts';

const Home = () => {
  return (
    <div className="home">
      <div className='parent-main'>
        <h2>I am a software engineer living in Venray, and in this website you can see what I am working on. <Link to="/projects">Read more</Link></h2>
      </div>
      <ProjectCard img={ProgrammingIMG} alt="Project preview" side="Left" title={programTitle} text={programText} cv={true} />
      <ProjectCard side="Center" title={loremIpsumTitle} text={loremIpsumText} />
      <ProjectCard img={ProgrammingIMG} alt="Project preview" side="Right" title={programTitle} text={programText} socials={true} />
    </div>
  )
};

export default Home;